import { FromSchema } from 'json-schema-to-ts';
import { jsonSchemaTypeHints } from './canonicalSchemaUtils';
import {
  ALL_FUEL_KINDS,
  AQUEDUCT_VERSION,
  WASTE_LIFECYCLES,
  WASTE_MATERIAL,
  WASTE_TREATMENTS,
} from '../batSchemas/constants';

import {
  ENERGY_KIND_VALUES,
  EU_POLLUTANT_RELEASE_AND_TRANSFER_REGISTER_POLLUTANT_GROUPS,
  MICROPLASTICS_OUTFLOW_MEDIUM,
  SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM,
  SUBSTANCE_OF_CONCERN,
  SUBSTANCE_OUTFLOW_MEDIUM,
} from './constants';
import PipelineResultSchema from './PipelineResultSchema';

export const SHOULD_EXCLUDE_TAG_NAME = 'should_exclude';
// We don't use true/false because the tagging editor assumes they're booleans
export const SHOULD_EXCLUDE_TAG_VALUE_EXCLUDE = 'yes';
export const SHOULD_EXCLUDE_TAG_VALUES = [
  SHOULD_EXCLUDE_TAG_VALUE_EXCLUDE,
  'no',
];

export const FUEL_COMBUSTION_KIND_VALUES = ['stationary', 'mobile'];

export const MEASUREMENT_FIDELITIES = [
  'ActivityBased',
  'Cee',
  'IndirectActivityBased',
  'SpendBased',
  'SquareFootageBased',
  'SupplierSpendBased',
  'UtilitiesBased',
] as const;
export type MeasurementFidelity = (typeof MEASUREMENT_FIDELITIES)[number];

/**
 * If you're editing this, make sure to also update the TSchema version:
 * shared-universal/TSchema/schemas/footprint/PipelineResultWithTagsSchema.ts
 */
const schema = jsonSchemaTypeHints({
  type: 'object',
  title: 'MergedPipelineResultsWithGlobalTags',
  description:
    'Merged pipeline results after default ontology and other global tags have been applied.',
  properties: {
    ...PipelineResultSchema.properties,
    __pipeline_id: {
      description: 'The ID of the pipeline result the row is sourced from.',
      type: 'string',
      examples: ['plr_2dC4CK6wq9dh3FDvTi7y'],
    },
    __pipeline_name: {
      description: 'The name of the pipeline result the row is sourced from.',
      type: 'string',
      examples: ['Fresh run (usr_2uZ3XddVpzArpEjTPe3p)'],
    },
    building_name: {
      description: 'The building name associated with the pipeline result row',
      type: 'string',
      examples: ['Montreal Office'],
    },
    measurement_fidelity: {
      description: 'The fidelity of measurement methodology',
      type: 'string',
      enum: MEASUREMENT_FIDELITIES,
      examples: ['ActivityBased'],
    },
    emissions_category_1: {
      description: 'First level emissions category',
      type: 'string',
      examples: ['electricity'],
    },
    emissions_category_2: {
      description: 'Second level emissions category',
      type: 'string',
      examples: ['natural_gas'],
    },
    emissions_category_3: {
      description: 'Third level emissions category',
      type: 'string',
      examples: ['manufacturing'],
    },
    fuel_combustion_kind: {
      description: 'The kind of fuel combustion',
      type: 'string',
      enum: FUEL_COMBUSTION_KIND_VALUES,
      examples: FUEL_COMBUSTION_KIND_VALUES,
    },
    fuel_kind: {
      description: 'The kind of fuel consumed',
      type: 'string',
      enum: ALL_FUEL_KINDS,
      examples: ALL_FUEL_KINDS,
    },
    energy_kind: {
      description: 'The primary form by which energy was carried',
      type: 'string',
      enum: ENERGY_KIND_VALUES,
      examples: ENERGY_KIND_VALUES,
    },
    asset_id: {
      description:
        'The finance asset ID associated with the pipeline result row',
      type: 'string',
      examples: ['fnh_2d555wpcak1oA8aEiPBs'],
    },
    waste_material: {
      description: 'The waste material with the pipeline result row',
      type: 'string',
      enum: WASTE_MATERIAL,
      examples: ['aluminum_cans'],
    },
    waste_treatment: {
      description:
        'The waste treatment associated with the pipeline result row',
      type: 'string',
      enum: WASTE_TREATMENTS,
      examples: ['landfilled'],
    },
    waste_lifecycle: {
      description:
        'The waste lifecycle associated with the pipeline result row',
      type: 'string',
      enum: WASTE_LIFECYCLES,
      examples: ['operations'],
    },
    is_waste_hazardous: {
      description:
        'Whether or not the waste associated with the pipeline result row is hazardous',
      type: 'boolean',
      examples: [true],
    },
    is_waste_csrd_hazardous: {
      description:
        'Whether or not the waste associated with the pipeline result row is hazardous according to CSRD regulations',
      type: 'boolean',
      examples: [true],
    },
    is_waste_radioactive: {
      description:
        'Whether or not the waste associated with the pipeline result row is radioactive',
      type: 'boolean',
      examples: [true],
    },
    pollutant_cas_number: {
      description:
        'The pollutant cas number associated with the pipeline result row',
      type: 'string',
      examples: ['107-06-2'],
    },
    eu_pollutant_release_and_transfer_register_pollutant_group: {
      description:
        'The pollutant group associated with the pipeline result row. The ontology comes from the EU Pollutant Release and Transfer Register.',
      type: 'string',
      enum: EU_POLLUTANT_RELEASE_AND_TRANSFER_REGISTER_POLLUTANT_GROUPS,
      examples: ['non_methane_volatile_organic_compounds'],
    },
    pollutant_substance_of_concern: {
      description:
        'The pollutant substance of concern associated with the pipeline result row',
      type: 'string',
      enum: SUBSTANCE_OF_CONCERN,
      examples: ['substance_of_very_high_concern'],
    },
    pollutant_substance_of_concern_hazard_class: {
      description:
        'The pollutant substance of concern hazard class associated with the pipeline result row',
      type: 'string',
      examples: ['ed_hh_1'],
    },
    substance_inflow_medium: {
      description: 'The medium through which the substance entered the system',
      type: 'string',
      enum: SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM,
      examples: ['procured'],
    },
    microplastics_inflow_medium: {
      description:
        'The medium through which the microplastics entered the system',
      type: 'string',
      enum: SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM,
      examples: ['procured'],
    },
    substance_outflow_medium: {
      description: 'The medium through which the substance left the system',
      type: 'string',
      enum: SUBSTANCE_OUTFLOW_MEDIUM,
      examples: ['air_emission'],
    },
    microplastics_outflow_medium: {
      description: 'The medium through which the microplastics left the system',
      type: 'string',
      enum: MICROPLASTICS_OUTFLOW_MEDIUM,
      examples: ['emission'],
    },
    [SHOULD_EXCLUDE_TAG_NAME]: {
      description:
        'Whether to exclude this row from the footprint snapshot. Not a boolean because tagging only supports strings.',
      type: 'string',
      enum: SHOULD_EXCLUDE_TAG_VALUES,
    },
    aqueduct_stable_row_id: {
      description:
        'The corresponding Aqueduct stable ID (aq30_id) for the location at which this water activity occurred.',
      type: 'string',
    },
    aqueduct_version: {
      description:
        'The Aqueduct dataset version that the aqueduct_stable_row_id corresponds to.',
      type: 'string',
      enum: AQUEDUCT_VERSION,
    },
    material_sustainable_sourcing_scheme: {
      description:
        'If the material is sustainably sourced, the certification scheme used.',
      type: 'string',
    },
    material_application_of_cascading_principle: {
      description:
        'A description of how the material was put into an order of value to create resource effectiveness.',
      type: 'string',
    },
    material_is_biological: {
      description:
        'Whether the material is derived from or produced by biological organisms.',
      type: 'string',
    },
    material_is_secondary: {
      description: 'Whether the material is not a virgin material.',
      type: 'string',
    },
    material_is_recyclable: {
      description: 'Whether the material can be recycled.',
      type: 'string',
    },
  },
  required: [
    ...PipelineResultSchema.required,
    '__pipeline_id',
    '__pipeline_name',
  ],
} as const);

export interface IMergedPipelineResultsWithGlobalTags
  extends FromSchema<typeof schema> {}
export default schema;
