import { FromSchema } from 'json-schema-to-ts';
import { GQCanonicalSchema, GQGridColumnFormat } from '../generated/graphql';
import { DeepReadonly } from '../utils/utilTypes';
import { z } from 'zod';
import { BatField } from '../batSchemas/BatCollection';
import { Immutable } from 'immer';
import * as Yup from 'yup';
import { BadDataError } from '../errors/BadDataError';

export function gridColumnFormatToFieldType(
  format: GQGridColumnFormat
): CanonicalSchemaFieldType {
  switch (format) {
    case GQGridColumnFormat.Currency:
      return 'integer';
    case GQGridColumnFormat.Date:
      return 'string';
    case GQGridColumnFormat.Integer:
      return 'integer';
    case GQGridColumnFormat.Float:
      return 'number';
    case GQGridColumnFormat.Percentage:
      return 'number';
    default:
      return 'string';
  }
}

// If we want to add another one, we need to also add it in
// createJsonSchemaValidator and OneSchemaTemplateService.test.ts

export const CanonicalSchemaFieldFormatDesc = {
  'date-time': 'Date and time in ISO-8601 format',
  date: 'Date in ISO-8601 format',
  'us-date': 'Date in US format',
  'eu-date': 'Date in EU format',
  'us-date-time': 'Date and time in US format',
  'eu-date-time': 'Date and time in EU format',
  'us-number': 'Number in comma-separated US format',
  'eu-number': 'Number in dot-separated EU format',
  'epoch-days': 'Number of days since 1970-01-01',
  'epoch-seconds': 'Number of seconds since 1970-01-01',
  'epoch-millis': 'Number of milliseconds since 1970-01-01',
};

export type CanonicalSchemaFieldFormat =
  keyof typeof CanonicalSchemaFieldFormatDesc;

export const CanonicalSchemaFieldTypeDesc = {
  string: 'String',
  number: 'Number',
  integer: 'Integer',
  boolean: 'Boolean',
};
export type CanonicalSchemaFieldType =
  keyof typeof CanonicalSchemaFieldTypeDesc;

export type CanonicalSchemaFieldValue = string | number | boolean | null;

export const CanonicalSchemaDefinitionFieldZod = z.object({
  type: z.enum(['string', 'number', 'boolean', 'integer']),
  description: z.string(),
  format: z
    .enum([
      'date-time',
      'date',
      'us-date',
      'eu-date',
      'us-date-time',
      'eu-date-time',
      'us-number',
      'eu-number',
      'epoch-days',
      'epoch-seconds',
      'epoch-millis',
    ])
    .optional(),
  enum: z.string().array().optional(),
  examples: z.union([z.string(), z.number(), z.boolean()]).array().optional(),
  default: z.union([z.string(), z.number(), z.boolean()]).optional(),
  displayName: z.string().optional(),
  unit: z.string().optional(),
  unitColumn: z.string().optional(),

  deprecated: z.boolean().optional(),
  storeAsHundredth: z.boolean().optional(),

  nullable: z.optional(z.NEVER),
});

export interface ICanonicalSchemaDefinitionField
  extends z.infer<typeof CanonicalSchemaDefinitionFieldZod> {}

export type CanonicalSchemaDefinitionField =
  DeepReadonly<ICanonicalSchemaDefinitionField>;

/**
 * This interface is a subset of JSON Schema: it enforces that we populate
 * certain JSON Schema fields in certain ways. It's one of the ways we constrain
 * our definitions (the other is unit tests).
 */
export type CanonicalSchemaDefinition<T = CanonicalSchemaDefinitionField> =
  DeepReadonly<{
    $id?: string;
    $schema?: string;
    type: 'object';
    title: string;
    description: string;
    properties: Record<string, T>;
    // By default patternProperties are used only on read validation
    // to accept pattern fields. If acceptOnWrite is set to true, any pattern
    // fields will also be written e.g. when using fileToTypecastParquet()
    patternProperties?: Record<string, T & { acceptOnWrite?: boolean }>;
    additionalProperties?: boolean;
    required?: Array<string>;
  }>;

/**
 * Asserts that a readonly object matches our JSON Schema type, and gives us
 * hints as we code the object.
 */
export function jsonSchemaTypeHints<T extends CanonicalSchemaDefinition>(
  schema: T
): T {
  return schema;
}

/**
 * By default, dates are stored as strings with `format`s set to `date` or
 * `date-time`. This type converts such fields into Date objects. If you have an
 * object satisfying a CanonicalSchema except with Date values, it'll satisfy
 * this type.
 */
export type FromSchemaWithDates<T extends CanonicalSchemaDefinition> =
  FromSchema<
    T,
    {
      deserialize: [
        { pattern: { type: 'string'; format: 'date' }; output: Date },
        { pattern: { type: 'string'; format: 'date-time' }; output: Date },
      ];
    }
  >;

/**
 * Converts the any-typed JSONString from the CanonicalSchema GQL type into
 * a CanonicalSchemaDefinition.
 */
export function getCanonicalSchemaDefinition(
  gqlCanonicalSchemaJsonSchema: GQCanonicalSchema['jsonSchema']
): CanonicalSchemaDefinition {
  return gqlCanonicalSchemaJsonSchema;
}

export function canonicalType(str: string): CanonicalSchemaFieldType {
  if (str in CanonicalSchemaFieldTypeDesc) {
    return str as CanonicalSchemaFieldType;
  } else {
    throw new Error(`Unknown canonical type ${str}`);
  }
}

export function canonicalFormat(str: string): CanonicalSchemaFieldFormat {
  if (str in CanonicalSchemaFieldFormatDesc) {
    return str as CanonicalSchemaFieldFormat;
  } else {
    throw new Error(`Unknown canonical format ${str}`);
  }
}

const fieldValueOneOfYupSchema = Yup.array(Yup.string().defined()).defined();

/**
 * fieldToJsonSchemaProperty transforms a field to a JSON Schema property.
 * @param field the field to convert
 * @returns a JSON Schema property
 */
export function fieldToJsonSchemaProperty(
  // { fieldValueOneOf: any } is added to handle other types,
  // like the domain type BusinessActivityTypeFieldFields.
  // This loose typing is fine because we validate it with Yup anyways.
  field: Immutable<BatField & { fieldValueOneOf: any }>
): CanonicalSchemaDefinitionField {
  const description = field.description;
  const baseResult = { description };

  switch (field.fieldType) {
    case 'BOOLEAN':
      return { ...baseResult, type: 'boolean' };
    case 'FLOAT':
      return { ...baseResult, type: 'number' };
    case 'INT':
      return { ...baseResult, type: 'integer' };
    case 'JSON':
    case 'STRING':
      return { ...baseResult, type: 'string' };
    case 'TIMESTAMP':
      return { ...baseResult, type: 'string', format: 'date-time' };
    case 'DATE':
      return { ...baseResult, type: 'string', format: 'date' };
    case 'ONE_OF':
      let enumList: Array<string> = [];
      try {
        enumList = fieldValueOneOfYupSchema.validateSync(field.fieldValueOneOf);
      } catch (error: any) {
        BadDataError.invariant(`Unexpected shape of 'fieldValueOneOf'`, error);
      }
      return {
        ...baseResult,
        type: 'string',
        enum: enumList,
      };
    default:
      throw new BadDataError(
        `Conversion of type '${field.fieldType}' to a JSON Schema property is not implemented`
      );
  }
}
